import { Modal, ModalOverlay, useDisclosure } from '@chakra-ui/react';
import { useState } from 'react';
import * as React from 'react';

export const useModal = <Props,>({
  Component,
  size = { base: 'full', md: 'lg' },
}: {
  Component: React.FC<Props>;
  size?: React.ComponentProps<typeof Modal>['size'];
}): {
  open: (props: Props) => void;
  element: React.ReactElement;
} => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [contentProps, setContentProps] = useState<Props | null>(null);

  return {
    open: (props) => {
      setContentProps(props);
      onOpen();
    },
    element: (
      <Modal onClose={onClose} size={size} scrollBehavior='inside' isOpen={isOpen}>
        {contentProps && (
          <>
            <ModalOverlay />
            <Component {...contentProps} />
          </>
        )}
      </Modal>
    ),
  };
};
